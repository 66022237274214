import React, { useState } from 'react'
import DateTimePicker from 'react-datetime-picker';
import './AddUrgency.css';

import moment from "moment";
import 'moment/locale/is';

import { getToken } from '../../Utils/Common';

const token = getToken();

function AddUrgency() {
    
    const actiontaken = 9;

    let handleChangeFace = async (e) => {
        setFace(e);
        setPoints_face(e)
        getTotal();
    }

    let handleChangeMunnhol = async (e) => {
        setMunnhol(e);
        setPoints_munnhol(e)
        getTotal();
    }

    let handleChangeKvidur = async (e) => {
        setKvidur(e);
        setPoints_kvidur(e)
        getTotal();
    }

    let handleChangeOndun = async (e) => {
        setOndun(e);
        setPoints_ondun(e)
        getTotal();
    }

    let handleChangeKynfaeri = async (e) => {
        setKynfaeri(e);
        setPoints_kynfaeri(e)
        getTotal();
    }

    let handleChangeLleg = async (e) => {
        setLleg(e);
        setPoints_ll(e)
        getTotal();
    }

    let handleChangeRleg = async (e) => {
        setRleg(e);
        setPoints_rl(e)
        getTotal();
    }

    let handleChangeLHand = async (e) => {
        setLHand(e);
        setPoints_lh(e)
        getTotal();
    }

    let handleChangeRHand = async (e) => {
        setRHand(e);
        setPoints_rh(e)
        getTotal();
    }

    let handleChangeHne = async (e) => {
        setHne(e);
        setPoints_hne(e)
        getTotal();
    }

    let getTotal = async () => {
        let totalF = 0;
        let totalP =  points_ll + points_rl + points_lh + points_rh + points_kvidur + points_face + points_hne + points_munnhol + points_ondun + points_kynfaeri;
        let erll = 0;
        let errl = 0;
        let erlh = 0;
        let errh = 0;
        let erkvidur = 0;
        let erface = 0;
        let erhne = 0;
        let ermunnhol = 0;
        let erondun = 0;
        let erkynfaeri = 0;

        if (points_ll == 0) { erll = 0; } else { erll = 1;}
        if (points_rl == 0) { errl = 0; } else { errl = 1;}
        if (points_lh == 0) { erlh = 0; } else { erlh = 1;}
        if (points_rh == 0) { errh = 0; } else { errh = 1;}

        if (points_kvidur == 0) { erkvidur = 0; } else { erkvidur = 1;}
        if (points_face == 0) { erface = 0; } else { erface = 1;}
        if (points_hne == 0) { erhne = 0; } else { erhne = 1;}
        if (points_munnhol == 0) { ermunnhol = 0; } else { ermunnhol = 1;}

        if (points_ondun == 0) { erondun = 0; } else { erondun = 1;}
        if (points_kynfaeri == 0) { erkynfaeri = 0; } else { erkynfaeri = 1;}

        totalF = erll + errl + erlh + errh + erkvidur + erface + erhne + ermunnhol + erondun + erkynfaeri;

        setPoints(totalP);
        setfjoldi(totalF);

        if(totalP <= 2) {
            setDiseaseActivity(1);
            setHEX(1);
            setReaction(1);
        } else if (totalP <= 4) {
            setDiseaseActivity(1);
            setHEX(1);
            setReaction(1);
        } else if (totalP <= 6) {
            setDiseaseActivity(3);
            setHEX(3);
            setReaction(3);
        } else {
            setDiseaseActivity(4);
            setHEX(4);
            setReaction(4);
        }
    }

    let handleSubmit = async (e) => {
        e.preventDefault();
        getTotal();
        let jsonstring =  "{" + points_ll + ", "+ points_rl + ", "+ points_lh + ", "+ points_rh + ", "+ points_kvidur + ", "+ points_face + ", "+ points_hne + ", "+ points_munnhol + ", "+ points_ondun + ", "+ points_kynfaeri + "}";
        try {

            let res = await fetch("/urgency", {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
                body: JSON.stringify({
                    registered: moment(value).format('yyyy-MM-DD'),
                    Started: Started,
                    Cause: Cause,
                    value: value,
                    points: points,
                    hex: hex,
                    DiseaseActivity: DiseaseActivity,
                    reaction: reaction,
                    actiontaken: actiontaken,
                    jsonstring: jsonstring,
                }),
            });

            let resJson = await res.json();
            console.log(resJson);
            if (res.status === 201) {
                setRleg("");
                setLleg("");
                setKvidur("");
                setFace("");
                setStarted("");
                setCause("");
                setMessage("Þetta gekk allt eins og í sögu!");
        } else {
            setMessage("Villa Kom upp!");
        }
    } catch (err) {
        console.log(err);
    }
};
    const [value, onChange] = useState(new Date());
    const [r_leg, setRleg] = useState("");
    const [l_leg, setLleg] = useState("");
    const [r_hand, setRHand] = useState("");
    const [l_hand, setLHand] = useState("");
    const [kvidur, setKvidur] = useState("");
    const [face, setFace] = useState("");
    const [munnhol, setMunnhol] = useState("");
    const [ondun, setOndun] = useState("");
    const [kynfaeri, setKynfaeri] = useState("");
    const [hne, setHne] = useState("");

    const [Started, setStarted] = useState("");
    const [Cause, setCause] = useState("");
    const [points, setPoints] = useState(0); 
    const [fjoldi, setfjoldi] = useState(0); 
    const [points_ll, setPoints_ll] = useState(0);
    const [points_rl, setPoints_rl] = useState(0);
    const [points_lh, setPoints_lh] = useState(0);
    const [points_rh, setPoints_rh] = useState(0);
    const [points_kvidur, setPoints_kvidur] = useState(0);
    const [points_face, setPoints_face] = useState(0);
    const [points_hne, setPoints_hne] = useState(0);
    const [points_munnhol, setPoints_munnhol] = useState(0);
    const [points_ondun, setPoints_ondun] = useState(0);
    const [points_kynfaeri, setPoints_kynfaeri] = useState(0);

    const [DiseaseActivity, setDiseaseActivity] = useState(1);
    const [hex, setHEX] = useState(1);
    const [reaction, setReaction] = useState(1);

    const [message, setMessage] = useState("");

    const [style, setStyle] = useState({display: 'none'});
    const [stylemunnhol, setMunnholStyle] = useState({display: 'none'});
    const [styleKvidur, setKvidurStyle] = useState({display: 'none'});
    const [styleOndun, setOndunStyle] = useState({display: 'none'});
    const [styleKynfaeri, setKynfaeriStyle] = useState({display: 'none'});
    const [styleLLeg, setLLegStyle] = useState({display: 'none'});
    const [styleRLeg, setRLegStyle] = useState({display: 'none'});
    const [styleLHand, setLHandStyle] = useState({display: 'none'});
    const [styleRHand, setRHandStyle] = useState({display: 'none'});
    const [styleHne, setHneStyle] = useState({display: 'none'});

    return (
        <main> 
            <h1 className="page-title">Skrá kast</h1>
            <form onSubmit={handleSubmit}>
                <div className="skra_mainwin">
                    <h3 className="page-title">Merktu inn á myndina hvar kastið er í líkamanum</h3>

                    <div className="merking">
                        <div className="likami kk">
                            <div className={"merki hofud val_"+ points_face} id="merkjaHofud" onClick={handle => {setStyle({display: 'block'}); }}><p className="tip">Höfuð (andlit og eða varir) {points_face}</p></div>
                            <div className={"merki munnhol val_"+ points_munnhol}  onClick={handle => {setMunnholStyle({display: 'block'}); }}><p className="tip">Munnhol, tunga og kok</p></div>
                            <div className={"merki ondunarfaeri val_"+ points_ondun} onClick={handle => {setOndunStyle({display: 'block'}); }}><p className="tip">Öndunarfæri</p></div>
                            <div className={"merki meltingarfaeri val_"+ points_kvidur} onClick={handle => {setKvidurStyle({display: 'block'}); }}><p className="tip">Meltingarfæri</p></div>
                            <div className={"merki kynfaeri val_"+ points_kynfaeri} onClick={handle => {setKynfaeriStyle({display: 'block'}); }}><p className="tip">Kynfæri</p></div>
                            <div className={"merki vinstrihond val_"+ points_lh} onClick={handle => {setLHandStyle({display: 'block'}); }}><p className="tip">Vinstri hönd</p></div>
                            <div className={"merki haegrihond val_"+ points_rh} onClick={handle => {setRHandStyle({display: 'block'}); }}><p className="tip">Hægri hönd</p></div>
                            <div  className={"merki hne val_"+ points_hne} onClick={handle => {setHneStyle({display: 'block'}); }}><p className="tip">Hné</p></div>
                            <div className={"merki vinstrifotur val_"+ points_ll} onClick={handle => {setLLegStyle({display: 'block'}); }}><p className="tip">Vinstri fótur</p></div>
                            <div className={"merki haegrifotur val_"+ points_rl} onClick={handle => {setRLegStyle({display: 'block'}); }}><p className="tip">Hægri fótur</p></div>

                            <div className="lidan hofud" style={style}>
                                <div>Höfuð</div>
                                <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={handle => {handleChangeFace(0);setStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={handle => {handleChangeFace(1);setStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={handle => {handleChangeFace(2);setStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={handle => {handleChangeFace(3);setStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={handle => {handleChangeFace(4);setStyle({display: 'none'})}}></i>
                                <div className="loka" onClick={handle => {setStyle({display: 'none'}); }}>x</div>
                            </div>
                            <div className="lidan munnhol" style={stylemunnhol}>
                                <div>Munnhol, tunga og kok</div>
                                <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={handle => {handleChangeMunnhol(0);setMunnholStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={handle => {handleChangeMunnhol(1);setMunnholStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={handle => {handleChangeMunnhol(2);setMunnholStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={handle => {handleChangeMunnhol(3);setMunnholStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={handle => {handleChangeMunnhol(4);setMunnholStyle({display: 'none'})}}></i>
                                <div className="loka" onClick={handle => {setMunnholStyle({display: 'none'}); }}>x</div>
                            </div>
                            <div className="lidan kvidur" style={styleKvidur}>
                                <div>Meltingarfæri</div>
                                <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={handle => {handleChangeKvidur(0);setKvidurStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={handle => {handleChangeKvidur(1);setKvidurStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={handle => {handleChangeKvidur(2);setKvidurStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={handle => {handleChangeKvidur(3);setKvidurStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={handle => {handleChangeKvidur(4);setKvidurStyle({display: 'none'})}}></i>
                                <div className="loka" onClick={handle => {setKvidurStyle({display: 'none'}); }}>x</div>
                            </div>
                            <div className="lidan ondun" style={styleOndun}>
                                <div>Öndunarfæri</div>
                                <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={handle => {handleChangeOndun(0);setOndunStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={handle => {handleChangeOndun(1);setOndunStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={handle => {handleChangeOndun(2);setOndunStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={handle => {handleChangeOndun(3);setOndunStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={handle => {handleChangeOndun(4);setOndunStyle({display: 'none'})}}></i>
                                <div className="loka" onClick={handle => {setOndunStyle({display: 'none'}); }}>x</div>
                            </div>
                            <div className="lidan kynfaeri" style={styleKynfaeri}>
                                <div>Kynfæri</div>
                                <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={handle => {handleChangeKynfaeri(0);setKynfaeriStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={handle => {handleChangeKynfaeri(1);setKynfaeriStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={handle => {handleChangeKynfaeri(2);setKynfaeriStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={handle => {handleChangeKynfaeri(3);setKynfaeriStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={handle => {handleChangeKynfaeri(4);setKynfaeriStyle({display: 'none'})}}></i>
                                <div className="loka" onClick={handle => {setKynfaeriStyle({display: 'none'}); }}>x</div>
                            </div>
                            <div className="lidan vistrifotur" style={styleLLeg}>
                                <div>Vinstri fótur</div>
                                <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={handle => {handleChangeLleg(0);setLLegStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={handle => {handleChangeLleg(1);setLLegStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={handle => {handleChangeLleg(2);setLLegStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={handle => {handleChangeLleg(3);setLLegStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={handle => {handleChangeLleg(4);setLLegStyle({display: 'none'})}}></i>
                                <div className="loka" onClick={handle => {setLLegStyle({display: 'none'}); }}>x</div>
                            </div>
                            <div className="lidan haegrifotur" style={styleRLeg}>
                                <div>Hægri fótur</div>
                                <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={handle => {handleChangeRleg(0);setRLegStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={handle => {handleChangeRleg(1);setRLegStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={handle => {handleChangeRleg(2);setRLegStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={handle => {handleChangeRleg(3);setRLegStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={handle => {handleChangeRleg(4);setRLegStyle({display: 'none'})}}></i>
                                <div className="loka" onClick={handle => {setRLegStyle({display: 'none'}); }}>x</div>
                            </div>
                            <div className="lidan vistrihond" style={styleLHand}>
                                <div>Vinstri hönd</div>
                                <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={handle => {handleChangeLHand(0);setLHandStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={handle => {handleChangeLHand(1);setLHandStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={handle => {handleChangeLHand(2);setLHandStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={handle => {handleChangeLHand(3);setLHandStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={handle => {handleChangeLHand(4);setLHandStyle({display: 'none'})}}></i>
                                <div className="loka" onClick={handle => {setLHandStyle({display: 'none'}); }}>x</div>
                            </div>
                            <div className="lidan haegrihond" style={styleRHand}>
                                <div>Hægri hönd</div>
                                <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={handle => {handleChangeRHand(0);setRHandStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={handle => {handleChangeRHand(1);setRHandStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={handle => {handleChangeRHand(2);setRHandStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={handle => {handleChangeRHand(3);setRHandStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={handle => {handleChangeRHand(4);setRHandStyle({display: 'none'})}}></i>
                                <div className="loka" onClick={handle => {setRHandStyle({display: 'none'}); }}>x</div>
                            </div>
                            <div className="lidan hne" style={styleHne}>
                                <div>Hné</div>
                                <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={handle => {handleChangeHne(0);setHneStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={handle => {handleChangeHne(1);setHneStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={handle => {handleChangeHne(2);setHneStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={handle => {handleChangeHne(3);setHneStyle({display: 'none'})}}></i>
                                <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={handle => {handleChangeHne(4);setHneStyle({display: 'none'})}}></i>
                                <div className="loka" onClick={handle => {setHneStyle({display: 'none'}); }}>x</div>
                            </div>
                        </div>
                    </div>
                </div><div className="skra_upplysingar">
                    <h3 className="page-title">Dagsetning</h3>
                    <div>
                        <DateTimePicker onChange={onChange} value={value} format="y-MM-dd" disableClock="true" />
                    </div>
                    <h3 className="page-title">Hversu langt er síðan kastið byrjaði? (klst)</h3>
                    <select name="Started" value={Started} onChange={(e) => setStarted(e.target.value)}>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">>7 klst</option>
                    </select>
                    <h3 className="page-title">Hvað telur þú að hafi komið kasti af stað?</h3>
                    <select name="Cause" value={Cause} onChange={(e) => setCause(e.target.value)} >
                        <option value="1">Svefnleysi</option>
                        <option value="2">Spenna/kvíði</option>
                        <option value="3">Aðgerð</option>
                        <option value="4">Slys</option>
                        <option value="5">Áverki </option>
                        <option value="6">Sýking/kvef</option>
                        <option value="7">Lyf</option>
                        <option value="8">Óþekkt</option>
                        <option value="9">Annað</option>
                    </select><br/>
                    <p><button type="submit" className="buatilnotenda">Skrá bráðkasts</button></p>
                    <div className="message">{message ? <p>{message}</p> : null}</div>
                </div>
            </form>

        </main>
    )
}

export default AddUrgency