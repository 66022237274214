import axios from 'axios';
import { getToken, getSession, getRefreshToken, removeUserSession, setRefreshUserSession } from './Common';
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export const getUserName = () => {
    axios.get('/sessions/' + session ,{
        headers: { 
            'Authorization': token
        }
    }, { crossdomain: true })
    .then(res => {
        const username = res.data.data.user_name;
        const netfang = res.data.data.user_netfang;
        sessionStorage.setItem('fullname', username);
        sessionStorage.setItem('netfang', netfang);
        
    })
    .catch(error => {
        const response = error.response;
        if (response.status === 403) {
            const json = JSON.stringify({ refresh_token: refreshtoken });
            axios.patch('/sessions/'+session, json , {
                headers: { 
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            }, { crossdomain: true })
            .then(response => {
                console.log(response);
                setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                window.location.reload();
            })
            .catch(error => {
                const response = error.response;
                console.log(response);
            })
        } else {
            removeUserSession();
            window.location.reload();
        }
    })
    return sessionStorage.getItem('fullname') || null;
}