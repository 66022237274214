import React, { Component } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { getToken } from '../../Utils/Common';

import moment from "moment";
import 'moment/locale/is';

const token = getToken();

export class HAExpert extends Component {

    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get('/urgency',{
            headers: { 
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.urgency, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        console.log(posts)
        if (isLoaded) {
            return (
                <main>
                    <h1>HAExpert <Link to="/haexpert/skra" className="addUrgency">Skrá Alvarlegt bráðkasts</Link></h1>
                    <div className="urgencies">
                        {posts.map(post =>
                            <div className="urgency" key={post.id} style={{borderLeft: '5px solid #'+post.hex}}>
                                <h3>{moment(post.registered).format('MMMM Do YYYY')}</h3>
                                <h4>{post.ended}</h4>
                                {post.reaction} - {athugaStodu(post.status, post.id)}
                            </div>
                        )}
                    </div>
                </main>
            )
        } else {
            return (
                <main>
                    <h1>HAExpert <Link to="/haexpert/skra" className="addUrgency">Skrá Alvarlegt bráðkasts</Link></h1>
                    <div className="urgencies">
                        <div className="urgency">
                            Hleð inn...
                        </div>
                    </div>
                </main>
            )
        }
    }
}

function athugaStodu(p,id) {
    if (p == 0) {
        let thisIsMyCopy = "<a href='/haexpert/Loka/"+id+"'>Loka kasti</a>"
        return <div className="content" dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>
    } else {
        let thisIsMyCopy = "<span>Kasti Lokið</span>";
        return <div className="content" dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>
    }
}

export default HAExpert