import axios from 'axios';

export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
}

export const getRefreshToken = () => {
    return sessionStorage.getItem('refreshtoken') || null;
}

export const getToken = () => {
    return sessionStorage.getItem('token') || null;
}

export const getSession = () => {
    return sessionStorage.getItem('session') || null;
}

export const removeUserSession = () => {
    const session = sessionStorage.getItem('session');
    const token = sessionStorage.getItem('token');
    axios.delete('/sessions/' + session ,{
        headers: { 
            'Authorization': token
        }
    })
    
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshtoken');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('fullname');
    sessionStorage.removeItem('netfang');
}

export const setUserSession = (token, refreshtoken, user, session) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refreshtoken', refreshtoken);
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('session', session);
    window.location.reload();
}

export const setRefreshUserSession = (token, refreshtoken) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refreshtoken', refreshtoken);
    window.location.reload();
}