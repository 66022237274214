import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { getSession } from '../../Utils/Common';
import { getUserName } from '../../Utils/GetUserInfo';

import './Header.css';

const session = getSession();

class Header extends Component {
    render() {
        const headerclass = "header " + this.props.flokkur;
        if (session) {
            const username = getUserName();
            return (
                <header className={headerclass}>
                    <div className="wrapper">
                        <h1 className="logo"><NavLink to="/dashboard" activeClassName="selected">Expeda</NavLink></h1>
                        <div id="hamborgari">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div id="menu">
                            <ul className="menu">
                                <li><NavLink to="/samskipti" activeClassName="selected">Samskipti</NavLink></li>
                                <li className="divider "><NavLink to="/haexpert" activeClassName="selected">HAExpert</NavLink></li>
                                <li className="btn_inn "><a href="/minsida" title={username}>Mín síða</a></li>
                                <li className="btn "><a href="/haexpert/skra">Skrá bráðkasts</a></li>
                            </ul>
                        </div>
                    </div>
                </header>
            );
        } else {
            return (
                <header className={headerclass}>
                    <div className="wrapper">
                        <h1 className="logo"><NavLink to="/" activeClassName="selected">Expeda</NavLink></h1>
                        <div id="hamborgari">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div id="menu">
                            <ul className="menu">
                                <li><NavLink to="/" activeClassName="selected">Forsíða</NavLink></li>
                                <li className="divider "><NavLink to="/um-okkur" activeClassName="selected">Um Okkur</NavLink></li>
                                <li className="btn_inn "><NavLink to="/login" activeClassName="selected">Innskrá</NavLink></li>
                                <li className="btn "><NavLink to="/nyskra" activeClassName="selected">Nýskrá</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </header>
            );
        }
    }
}

export default Header;