import React, { Component } from 'react'
import axios from 'axios';

export class UmOkkur extends Component {
    state = { posts: [], isLoaded: false }

    componentDidMount () {
        axios.get('/pages/2')
        .then(res => this.setState({ posts: res.data, isLoaded: true }))
        .catch(err => console.log(err))
    }

    render() {
        const {posts, isLoaded} = this.state;
        console.log(posts.title)
        if (isLoaded) {
            return (
                <main>
                    <div className="main">
                        <h3 className="yfirfyrirsogn">Expeda</h3>
                        <h2 className="fyrirsogn">{posts.title.rendered}</h2>
                        <div dangerouslySetInnerHTML={{__html: posts.content.rendered}} />
                    </div>
                </main>
            );
        } else {
            return (
                <main>
                    <div className="main"></div>
                    <p className="hled">Hleð</p>
                </main>
            )
        }
    }

}

export default UmOkkur