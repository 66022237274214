import React, { useState } from 'react';
import { getToken } from '../../Utils/Common';

const token = getToken();

function NewTask(props) {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [deadline, setDeadline] = useState("");
    const [completed, setCompleted] = useState("");
    const [message, setMessage] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {

            let res = await fetch("/tasks", {
                method: "POST",
                headers: { 
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title: title,
                    description: description,
                    completed: 'N',
                }),
            });

            let resJson = await res.json();
            console.log(resJson);
            if (res.status === 201) {
                setTitle("");
                setDescription("");
                setDeadline("");
                setCompleted("");
                setMessage("Þetta gekk allt eins og í sögu!");
        } else {
            setMessage("Ekki tókst að búa til notanda!");
        }
    } catch (err) {
        console.log(err);
    }
};

    return (
        <main>
            <div className="innskraningssida">
                <h3 className="login">Mitt svæði</h3>
                <h2 className="nyskra">Nýskráning</h2>
                <form onSubmit={handleSubmit}>
                    <input type="text" value={title} placeholder="Titill" onChange={(e) => setTitle(e.target.value)} />
                    <input type="text" value={description} placeholder="Lýsing" onChange={(e) => setDescription(e.target.value)} />
                    <input type="text" value={deadline} placeholder="Endar þann" onChange={(e) => setDeadline(e.target.value)} />
                    <input type="text" value={completed} placeholder="Búið" onChange={(e) => setCompleted(e.target.value)} />
                    <button type="submit" className="buatilnotenda">Búa til!</button>
                    <div className="message">{message ? <p>{message}</p> : null}</div>
                </form>
            </div>
        </main>
    );
}

export default NewTask;