import React from 'react';
import {BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from './components/templates/Header';

import Dashboard from './components/Views/Dashboard';
import Login from './components/Views/Login';
import Nyskra from './components/Views/Nyskra';
import MainWin from './components/Views/MainWin';
import User from './components/Views/User';
import HAExpert from './components/Views/HAExpert';
import AddUrgency from './components/Views/AddUrgency';
import CloseUrgency from './components/Views/CloseUrgency';

import PublicRoute from './Utils/PublicRoute';
import PrivateRoute from './Utils/PrivateRoute';
import Tasks from './components/Views/Tasks';
import NewTask from './components/Views/NewTask';

import UmOkkur from './components/pages/Um-okkur'

function App() {
    return (
        <div className="App">
            <script src="https://kit.fontawesome.com/731b25c641.js" crossOrigin="anonymous"></script>
            <BrowserRouter>
                <Header/>
                <Switch>
                    <PublicRoute path="/login" component={Login} />
                    <PublicRoute path="/nyskra" component={Nyskra} />
                    <Route path="/um-okkur" component={UmOkkur} />
                    
                    <PrivateRoute path="/samskipti/nytt" component={NewTask} />
                    <PrivateRoute path="/samskipti" component={Tasks} />
                    <PrivateRoute path="/haexpert/skra" component={AddUrgency} />
                    <PrivateRoute path="/haexpert/Loka/:id" component={CloseUrgency} />
                    <PrivateRoute path="/haexpert" component={HAExpert} />
                    <PrivateRoute path="/dashboard" component={Dashboard} />
                    <PrivateRoute path="/minsida" component={User} />
                    <Route path="/" component={MainWin} />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;