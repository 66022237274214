import React, { Component } from 'react'
import axios from 'axios';

import { getToken, getSession, getRefreshToken, removeUserSession, setRefreshUserSession } from '../../Utils/Common';

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class Tasks extends Component {

    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get('/tasks',{
            headers: { 
                'Authorization': token,
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(res => this.setState({ posts: res.data.data.tasks, isLoaded: true }))
        .catch(error => {
			const response = error.response;

            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch('/sessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <main>
                    <h1>Samskipti</h1>
                    <a href="/samskipti/nytt">Senda skilaboð</a><br/>
                    <div className="verkin">
                        {posts.map(post =>
                            <div className="item" key={post.id}>
                                <h3>{post.title}</h3>
                            </div>
                        )}
                    </div>
                </main>
            )
        } else {
            return (
                <main>
                    <h1>Er að ná í gögn</h1>
                </main>
            )
        }
    }
}

export default Tasks