import React, { Component } from 'react'

export class Dashboard extends Component {

    render() {

        return (
            <main>
                <h1>Yfirlit</h1>
            </main>
        )
    }
}

export default Dashboard