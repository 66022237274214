import React, { useState } from 'react';
import { setUserSession } from '../../Utils/Common';
import axios from 'axios';

function Login(props) {
    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState(null);

    const handleLogin = () => {
        setError(null);
        setLoading(true);
        var data = {
            'username': username.value,
            'password': password.value
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        axios.post('/sessions',data, { headers: headers }, { crossdomain: true }).then(response => {
            setLoading(false);
            if (response.data.data.access_token) {
                setUserSession(response.data.data.access_token, response.data.data.refresh_token, username.value, response.data.data.session_id);
                props.history.push('/');
            } else {
                setError("Error in login");
            }
        }).catch(error => {
            setLoading(false);
            setError("Innskráning tókst ekki!");
            //setError(error.data.data.messages);
            //if (error.data.statusCode === 400) setError(error.data.messages);
            //else setError("Innskráning tókst ekki!");
        });

    }

    return (
        <main>
            <div className="innskraningssida">
                <h3 className="login">Mitt svæði</h3>
                <h2 className="login">Innskráning</h2>
                <p className="login">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                <div style={{ marginTop: 10 }}>
                    <input type="text" {...username} autoComplete="new-password" placeholder="Notendanafn" />
                </div>
                <div style={{ marginTop: 10 }}>
                    <input type="password" {...password} autoComplete="new-password" placeholder="Lykilorð" />
                </div>
            {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
            <div style={{ marginTop: 20 }}><input type="button" className="loginbtn" value={loading ? 'Hleð...' : 'Innskrá'} onClick={handleLogin} disabled={loading} /><br /></div>
            <div className="gleymt"><a href="/">Gleymt lykilorð!</a></div>
            </div>
        </main>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export default Login;