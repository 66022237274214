import React, { Component } from 'react'
import axios from 'axios';
import { getToken, removeUserSession } from '../../Utils/Common';

const token = getToken();

const handleLogout = () => {
    removeUserSession();
    window.location.reload();
}

export class User extends Component {

    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get('/user',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.user, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        console.log(posts)
        if (isLoaded) {
            return (
                <main>
                    <h1>Upplýsingar <button onClick={handleLogout} className="innskraning">Útskrá</button></h1>
                    <div className="itemfull">
                        <div className="icon"><i className="fa-regular fa-user"></i></div>
                        <div className="info">
                            <h4>Bakgrunnsupplýsingar</h4>
                            Nafn: <strong>{sessionStorage.getItem('fullname') || null}</strong> <br/>
                            <div className="question">
                                <label>Kennitala:</label> <input type="text" name="kt" value={posts[0].ssn} />
                            </div>
                            <div className="question">
                                <label>Kyn: {posts[0].kyn}</label> 
                                <input type="radio" id="Karl" name="kyn" value="Karl"/>
                                <label htmlFor="Karl">Karl</label>
                                <input type="radio" id="Kona" name="kyn" value="Kona"/>
                                <label htmlFor="Kona">Kona</label>
                                <input type="radio" id="Annad" name="kyn" value="Annað"/>
                                <label htmlFor="Annad">Annað</label>
                            </div>
                        </div>
                    </div>
                    <div className="itemfull">
                        <div className="icon"><i className="fa-regular fa-envelope"></i></div>
                        <div className="info">
                            <h4>Samskipta upplýsingar</h4>
                            Netfang <strong>{sessionStorage.getItem('netfang') || null}</strong><br/>
                            Símanúmer <strong>{posts[0].tel}</strong> {posts[0].address}
                        </div>
                    </div>
                    <div className="itemfull">
                        <div className="icon"><i className="fa-regular fa-lock"></i></div>
                        <div className="info">
                            <h4>Lykillorð</h4>
                            <strong>Nafnið á notandanum</strong>
                        </div>
                    </div>
                    <div className="itemfull">
                        <div className="icon"><i class="fa-regular fa-pills"></i></div>
                        <div className="info">
                            <h4>Lyf</h4>
                            <div className="moreInfo">

        Núverandi HAE lyf:
    •	Engin meðferð □							Score (0)
    •	Fyrirbyggjandi meðferð:  						Score (1)
    o	Cinryze 
    o	Berinert undir húð
    	2x í viku
    	Vikulega
    	Aðrahverja viku
    o	Berinert í æð
    	2x í viku
    	Vikulega
    	Aðrahverja viku
    o	Annað
    •	Meðferð við köstum (on demand) 
    o	Cinryze
    o	Berinert í æð
    o	Fyrazir

        Önnur lyf:
                __________________________________
        
                            </div>
                        </div>
                    </div>
                    
                </main>
            )
        }else {
            return (
                <main></main>
            )
        }
    }
}

export default User