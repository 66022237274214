import React, { useState } from 'react'
import DateTimePicker from 'react-datetime-picker';


import moment from "moment";
import 'moment/locale/is';

import './AddUrgency.css';

import { getToken } from '../../Utils/Common';

const token = getToken();

function CloseUrgency() {

    const id = 7;

    const [value, onChange] = useState(new Date());
    const [message, setMessage] = useState("");
    const [lengdkasts, setlengdkasts] = useState("");
    const [hvadvargert, sethvadvargert] = useState("");
    const [virkni, setvirkni] = useState("");
    const [fjarverafravinnu, setfjarverafravinnu] = useState("");
    
    let handleSubmit = async (e) => {
        e.preventDefault();
        try {

            let res = await fetch("/closeurgency", {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
                body: JSON.stringify({
                    ended: moment(value).format('yyyy-MM-DD'),
                    lengdkasts: lengdkasts,
                    hvadvargert: hvadvargert,
                    virkni: virkni,
                    fjarverafravinnu: fjarverafravinnu,
                    id: id
                }),
            });

            if (res.status === 201) {
                setlengdkasts("");
                sethvadvargert("");
                setvirkni("");
                setfjarverafravinnu("");
                setMessage("Þetta gekk allt eins og í sögu!");
            } else {
                setMessage("Villa Kom upp!");
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <main> 
            <h1 className="page-title">Loka kast {id}</h1>
            <form onSubmit={handleSubmit}>
                <h3 className="page-title">Kast endaði þann</h3>
                <div>
                    <DateTimePicker onChange={onChange} value={value} format="y-MM-dd" disableClock="true" />
                </div>
                <h3>Hversu lengi stóð kastið?</h3>
                <select name="lengdkasts" value={lengdkasts} onChange={(e) => setlengdkasts(e.target.value)} >
                    <option value="1">0-4 klst</option>
                    <option value="2">4-8 klst</option>
                    <option value="3">8-12 klst</option>
                    <option value="4">12-24 klst</option>
                    <option value="5">24-48 klst</option>
                    <option value="6">48-72 klst</option>
                    <option value="7">4-5 dagar</option>
                    <option value="8">6-7 dagar</option>
                    <option value="9">>7 dagar</option>
                </select>

                <h3>Hvað var gert?</h3>
                <select name="hvadvargert" value={hvadvargert} onChange={(e) => sethvadvargert(e.target.value)}>
                    <option value="9">Ekkert</option>
                    <option value="10">Hvíld</option>
                    <option value="11">Firazyr notað 1x</option>
                    <option value="12">Firazyr notað 2x</option>
                    <option value="13">Berinert í æð</option>
                    <option value="14">Cinryze í æð</option>
                    <option value="15">Leitaði til bráðaþjónustu heilbrigðisstofnunnar</option>
                    <option value="16">Ráðgjöf hjá hjúkrunarfræðing eða lækni</option>
                    <option value="17">Annað</option>
                </select>

                <h3>Hversu lengi var lyfið að virka þar til einkenni voru bærileg?</h3>
                <div>Frá lyfjagjöf:</div>
                <select name="virkni" value={virkni} onChange={(e) => setvirkni(e.target.value)}>
                    <option value="1">0 klst</option>
                    <option value="2">4 klst</option>
                    <option value="2">8 klst</option>
                    <option value="2">12 klst</option>
                    <option value="2">24 klst</option>
                    <option value="2">36 klst</option>
                    <option value="2">48 klst</option>
                </select>

                <h3>Leiddi kastið til fjarveru frá vinnu, námi eða getu til að sinna athöfnum daglegs lífs?</h3>
                <input type="radio" id="fjarverafravinnunei" name="fjarverafravinnuval" value="Nei"/>
                <label for="fjarverafravinnunei">Nei</label>
                <input type="radio" id="fjarverafravinnuja" name="fjarverafravinnuval" value="Ja" />
                <label for="fjarverafravinnuja">CSS</label><br/>

                <select name="fjarverafravinnu" value={fjarverafravinnu} onChange={(e) => setfjarverafravinnu(e.target.value)}>
                    <option value="1">0-4klst </option>
                    <option value="2">4-8 klst</option>
                    <option value="3">8-12 klst</option>
                    <option value="4">12-24klst</option>
                    <option value="5">24-48klst</option>
                    <option value="6">4-5 dagar</option>
                    <option value="7">6-7 dagar</option>
                    <option value="8">>7 dagar</option>
                </select>

                <p><button type="submit" className="buatilnotenda">Vista</button></p>

                {message}
            </form>
        </main>
    )
}

export default CloseUrgency