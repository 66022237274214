import React, { Component } from 'react'

export class MainWin extends Component {

    render() {

        return (
            <main>
                <section>
                    <h3 className="yfirfyrirsogn">Hvað er Expeda?</h3>
                    <h2 className="fyrirsogn">Ný og einfaldari leið að nálgast læknaþjónustu.</h2>
                    <p className="undirfyrirsogn">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque mollis convallis metus nec gravida. Nulla lobortis mi auctor ultrices congue.</p>
                    <div className="adgangur">
                        <a href="/login" className="takki">Innskrá</a><div className="brakethis"></div> eða <a href="/nyskra">Sækja um aðgang</a>.
                    </div>
                    <div className="background_mynd"><img src="/img/mynd_expeda.png" alt="Expeda" /></div>
                </section>
            </main>
        )
    }
}

export default MainWin